/* eslint-disable no-undef */
/* eslint-disable import/no-cycle */
import api from '../axios.service'

export default {
  get(data) {
    return api.get(`message_api/list?search=${data.search}&page=${data.page}&size=${data.size}&sortBy=${data.sortBy}&sortDesc=${data.sortDesc}`)
  },

  showMessage(data){
    return api.get(`message_api/show?search=${data.search}&date=${data.date}&status=${data.OrderStatus}`)
  },

  messageStore(data) {
    let getFormData = data

    let params = new FormData();
    for (var key in getFormData) {
      if (getFormData.hasOwnProperty(key) && getFormData[key]) {
        if (key == 'member_id'){
          for(var i=0;i<getFormData[key].length;i++){
            params.append('member_id[]', JSON.stringify(getFormData[key][i]));
          }
        }else{
          params.append(key, getFormData[key]);
        }
      }
    }

    let config = {
      header: {
        'Content-Type': 'multipart/form-data'
      }
    }
    return api.post(`message_api/store`, params, config)
  },

  messageUpdate(data) {
    let getFormData = data

    let params = new FormData();
    params.append('_method', 'PUT');
    for (var key in getFormData) {
      if (getFormData.hasOwnProperty(key) && getFormData[key]) {
        if (key == 'member_id'){
          for(var i=0;i<getFormData[key].length;i++){
            params.append('member_id[]', JSON.stringify(getFormData[key][i]));
          }
        }else{
          params.append(key, getFormData[key]);
        }
      }
    }
    let config = {
      header: {
        'Content-Type': 'multipart/form-data'
      }
    }
    return api.post(`message_api/update/${data.id}`, params, config)
  },

  messageDestroy(id) {
    // let getFormData = data

    let params = new FormData();

    return api.delete(`message_api/delete/${id}`, params, {})
  },

  messageChangeStatus(id)
  {

    let params = new FormData();
    params.append('_method', 'PUT');

    return api.post(`message_api/new_updateStatus/${id}`, params, {})
  }

}
