<template>
  <v-data-table
    :headers="headers"
    :items="messages"
    :search="search"
    :server-items-length="total"
    :options.sync="serverOptions"
    :sort-by.sync="sortBy"
    :sort-desc.sync="sortDesc"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <!-- <v-toolbar-title> -->
        <v-text-field
          v-model="search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
        <!-- </v-toolbar-title> -->
        <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
        <v-spacer></v-spacer>
        <v-dialog
          v-model="dialog"
          max-width="500px"
        >
          <template v-slot:activator="{ on, attrs }">
            <!-- <div class="d-flex justify-space-between"> -->
            <!-- <v-text-field v-model="search" label="Search" single-line hide-details></v-text-field> -->
            <v-btn
              color="primary"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
              @click="close"
            >
              เพิ่มการแจ้งเตือน
            </v-btn>
            <!-- </div> -->
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    v-if="editedItem.image_path"
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-img
                      label="รูปข่าวสาร"
                      :src="editedItem.image_path"
                    ></v-img>
                  </v-col>
                  <!--                  <v-col-->
                  <!--                    cols="12"-->
                  <!--                    sm="12"-->
                  <!--                    md="12"-->
                  <!--                  >-->
                  <!--                    <v-file-input-->
                  <!--                      :disabled="editedItem.sen_at != null"-->
                  <!--                      accept="image/png, image/jpeg, image/bmp"-->
                  <!--                      v-model="editedItem.file_example"-->
                  <!--                    label="รูปข่าวสาร"-->
                  <!--                    ></v-file-input>-->
                  <!--                  </v-col>-->

                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-checkbox
                      hide-details
                      hidden
                      value="Y"
                      v-model="editedItem.status"
                      :disabled="editedItem.sen_at != null"
                      label="ประกาศแจ้งเตือนทันที"
                    ></v-checkbox>

                  </v-col>

                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    Message Type
                    <v-radio-group
                      :disabled="editedItem.sen_at != null"
                      v-model="editedItem.message_type">
                      <v-radio
                        value="text"
                        label="Message text"></v-radio>
                      <v-radio
                        value="imagemap"
                        label="Message Image (1040*1040)"></v-radio>
                    </v-radio-group>
                  </v-col>

                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-textarea
                      :disabled="editedItem.sen_at != null"
                      v-model="editedItem.message_text"
                      placeholder="ข้อความ"
                      label="ข้อความ"></v-textarea>
                  </v-col>
                  ประกาศแจ้งเตือนทันที
                  <v-col
                    v-if="editedItem.message_type == 'imagemap'"
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-file-input
                      v-model="editedItem.file_example"
                      :disabled="editedItem.sen_at != null"
                      label="image"></v-file-input>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    การส่งถึง
                    <v-radio-group
                      :disabled="editedItem.sen_at != null"
                      v-model="editedItem.sen_to">
                      <v-radio
                        value="by_member_all"
                        label="ลูกค้าทั้งหมด"></v-radio>
                      <v-radio
                        value="by_identify_person"
                        label="รายบุคคล"></v-radio>
                    </v-radio-group>
                  </v-col>

                  <v-col
                    v-if="editedItem.sen_to == 'by_identify_person'"
                    cols="12"
                    sm="12"
                    md="12">
                    <v-select
                      :disabled="editedItem.sen_at != null"
                      label="รายชื่อลูกค้า"
                      multiple
                      :items="members"
                      item-text="member_name"
                      item-value="member_id"
                      v-model="editedItem.member_id"
                    >
                    </v-select>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                text
                @click="close"
              >
                Cancel
              </v-btn>
              <v-btn

                v-if="!editedItem.sen_at"
                color="primary"
                text
                @click="save"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="dialogDelete"
          max-width="600px"
        >
          <v-card>
            <v-card-title class="text-h5 mb-5">
              Are you sure you want to delete this item?
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                outlined
                @click="closeDelete"
              >
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                @click="deleteItemConfirm"
              >
                OK
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        {{ icons.mdiPencil }}
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        {{ icons.mdiDelete }}
      </v-icon>
    </template>

    <template v-slot:item.index="{ index }">
      {{ ((serverOptions.page - 1) * serverOptions.itemsPerPage) + index + 1 }}
    </template>
    <template v-slot:item.sent_type="{ item }">
      <span v-if="item.sen_to == 'by_identify_person'">รายบุคคล</span>
      <span v-if="item.sen_to == 'by_member_all'">ลูกค้าทั้งหมด</span>
    </template>
    <template v-slot:item.sentTo="{ item }">

      <div class="form-inline" style="display: flex">
        <div class="avatar" v-if="item.users[0]">
          <img
            @error="replaceUrl"
            class="img-avatar"
            :src="item.users[0].user.pictureUrl ? item.users[0].user.pictureUrl  : 'https://www.gravatar.com/avatar/a3175a452c7a8fea80c62a198a40f6c9?s=160&d=retro'"
            :title="item.users[0].user.displayName">
          <span class="avatar-status badge-success"></span>
        </div>
        <div class="avatar" v-if="item.users[1]">
          <img
            @error="replaceUrl"
            class="img-avatar" :src="item.users[1].user.pictureUrl"
            :title="item.users[1].user.displayName">
          <span class="avatar-status badge-success"></span>
        </div>
        <div class="avatar" v-if="item.users[2]">
          <img
            @error="replaceUrl"
            class="img-avatar" :src="item.users[2].user.pictureUrl"
            :title="item.users[2].user.displayName">
          <span class="avatar-status badge-success"></span>
        </div>
        <div class="avatar" v-if="item.users[3]">
          <img
            @error="replaceUrl"
            class="img-avatar" :src="item.users[3].user.pictureUrl"
            :title="item.users[3].user.displayName">
          <span class="avatar-status badge-success"></span>
        </div>
        <div class="avatar" v-if="item.users[4]">
          <img
            @error="replaceUrl"
            class="img-avatar" :src="item.users[4].user.pictureUrl"
            :title="item.users[4].user.displayName">
          <span class="avatar-status badge-success"></span>
        </div>

        <i v-if="item.users.length >= 5" class="fa fa-plus-circle" aria-hidden="true"></i>
      </div>


    </template>
    <template v-slot:item.status="{ item }">
      <span class="warning-badge" v-if="item.status == 'done'">ส่งแล้ว</span>
      <span class="success-badge" v-if="item.status == 'dont'">รอการส่ง</span>
    </template>
    <template v-slot:no-data>
      <!-- <v-btn
        color="primary"
        @click="initialize"
      >
        Reset
      </v-btn> -->
    </template>
  </v-data-table>
</template>

<script>
import {mdiPencil, mdiDelete} from '@mdi/js'
// import config from '../../../config'
import instance from '../../../axios.service'
import instance_message from '../../../services/messageApi'
import instance_member from '../../../services/member'
import Swal from "sweetalert2";

export default {
  setup() {
    return {
      icons: {
        mdiPencil,
        mdiDelete,
      },
    }
  },
  data: () => ({
    serverOptions: {
      page: 1,
      itemsPerPage: 10,
    },
    total: 0,
    messages: [],
    members: [],
    dialog: false,
    dialogDelete: false,
    sortBy: '',
    sortDesc: '',
    search: '',
    headers: [
      {
        text: 'ลำดับ',
        value: 'index',
        sortable: false
      },
      {text: 'ส่งถึง', value: 'sentTo', width: '50', sortable: false},
      {text: 'ข้อความ', value: 'message_text'},
      {text: 'ประเภทข้อความ', value: 'message_type'},
      {text: 'ประเภทส่งถึง', value: 'sent_type'},
      {text: 'สถานะ', value: 'status'},
      {text: 'ส่งวันที่', value: 'sen_at'},
      {text: 'สร้าง', value: 'created_at'},
      {text: 'แก้ไขข้อมูล', value: 'actions', sortable: false},
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {
      image_path: '',
      file_example: null,
      message_text: '',
      message_type: 'text',
      sen_at: null,
      sen_to: 'by_member_all',
      status: '',
      member_id: [],
    },
    defaultItem: {
      image_path: '',
      file_example: null,
      message_text: '',
      message_type: 'text',
      sen_at: null,
      sen_to: 'by_member_all',
      status: '',
      member_id: [],
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'เพิ่ม การแจ้งเตือน' : 'แก้ไข การแจ้งเตือน'
    },
  },

  watch: {
    serverOptions: {
      handler() {
        this.getMessage()
      },
    },
    search: {
      handler() {
        this.getMessage()
      },
    },
    // dialog(val) {
    //   val || this.close()
    // },
    // dialogDelete(val) {
    //   val || this.closeDelete()
    // },
  },

  created() {
    // this.initialize()
  },
  mounted() {
    // this.getUser()
    this.getMessage()
    this.getMember()
  },

  methods: {
    replaceUrl(e) {
      e.target.src = 'https://www.gravatar.com/avatar/a3175a452c7a8fea80c62a198a40f6c9?s=160&d=retro'
    },
    getMessage() {
      this.$store.state.isLoading = true
      var data = {
        search: this.search,
        size: this.serverOptions.itemsPerPage,
        page: this.serverOptions.page,
        data: '',
        sortBy: this.sortBy ?? "",
        sortDesc: this.sortDesc ?? ''
      }
      instance_message.get(data).then(res => {
        this.messages = res.data.data.data
        this.total = res.data.data.total
        this.serverOptions.itemsPerPage = this.serverOptions.itemsPerPage == -1 ? -1 : res.data.data.per_page
        this.serverOptions.page = res.data.data.current_page
        this.$store.state.isLoading = false
      }).catch(err => {
        this.$store.state.isLoading = false

      });
    },
    getMember() {
      var data = {
        search: '',
        sortBy : '',
        sortDesc : '',
        size : 9999999,
      }
      instance_member.getMember(data).then(res => {
        this.members = res.data.data.data
      }).catch(err => {

      });
    },
    editItem(item) {
      this.editedIndex = this.messages.indexOf(item)
      this.editedItem = {...item}
      if (this.editedItem.status == 'done') {
        this.editedItem.status = 'Y'
      }
      for (var i = 0; i < this.editedItem.users.length; i++) {
        this.editedItem.member_id = [];
        this.editedItem.member_id.push(this.editedItem.users[i].member_id)
      }
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.messages.indexOf(item)
      this.editedItem = {...item}
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.$store.state.isLoading = true
      instance_message.messageDestroy(this.editedItem.id)
        .then(res => {
          setTimeout(function () {
            Swal.fire({
              icon: 'success',
              title: res.data.message,
              showConfirmButton: false,
              timer: 1500
            })
          }, 150)
          this.getMessage();
          this.closeDelete()
        }).catch(err => {
        Swal.fire({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด !!',
          text: err.message,
        })
        this.$store.state.isLoading = false

        this.closeDelete()
      });
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = {...this.defaultItem}
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = {...this.defaultItem}
        this.editedIndex = -1
      })
    },

    save() {
      this.$store.state.isLoading = true
      if (this.editedIndex > -1) {
        instance_message.messageUpdate(this.editedItem)
          .then(res => {
            Swal.fire({
              icon: 'success',
              title: res.data.message,
              showConfirmButton: false,
              timer: 1500
            })
            this.getMessage()
            this.close()
          }).catch(err => {
          Swal.fire({
            icon: 'error',
            title: 'เกิดข้อผิดพลาด !!',
            text: err.message,
          })
          this.$store.state.isLoading = false

        });
      } else {
        instance_message.messageStore(this.editedItem)
          .then(res => {
            // this.messages.push(this.editedItem)
            Swal.fire({
              icon: 'success',
              title: res.data.message,
              showConfirmButton: false,
              timer: 1500
            })
            this.getMessage()
            this.close()

          }).catch(err => {
          Swal.fire({
            icon: 'error',
            title: 'เกิดข้อผิดพลาด !!',
            text: err.message,
          })
          this.$store.state.isLoading = false

        });
      }
    },
  },
}
</script>

<style scoped>
hr {
  background-color: #e5e5e5;
  height: 1px;
  border: none;
}
</style>
